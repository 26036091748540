/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import {
  Page,
  Card,
  Button,
  useIndexResourceState,
  IndexTable,
  TextStyle,
  Badge,
} from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import AppContext from '../context/AppContext';
import { toCLP, getTotal } from '../utils/utils';

const Order = () => {
  const { state } = useContext(AppContext);
  const [isloading, setIsLoading] = useState(true);
  const [listProjects, setlistProjects] = useState([]);
  const history = useHistory();
  const host = new URLSearchParams(location.search).get('host');
  const shop = new URLSearchParams(location.search).get('shop');

  const resourceName = {
    singular: 'orden',
    plural: 'ordenes',
  };

  const {
    selectedResources,
    allResourcesSelected
  } = useIndexResourceState(listProjects);

  const detailOrder = (id) => {
    history.push(`/order-detail/${id}?shop=${shop}&host=${host}`);
  };

  const consultOrder = () => {
    window
      .fetch(
        `${process.env.REACT_APP_BASEURL}/orders?shop=${shop}&host=${host}`,
        {
          method: 'GET',
        }
      )
      .then((response) => response.json())
      .then((resp) => {
        setlistProjects(resp);
        setIsLoading(false);
      })
      .catch(function err() {
        setIsLoading(false);
      });
  };

  const progressOrder = (id) => {
    setIsLoading(true);
    window
      .fetch(
        `${process.env.REACT_APP_BASEURL}/order-process?shop=${shop}&order=${id}&host=${host}`,
        {
          method: 'GET',
        }
      )
      .then((response) => response.json())
      .then(() => {
        setIsLoading(false);
        consultOrder();
      })
      .catch(function err() {
        setIsLoading(false);
        consultOrder();
      });
  };

  const fetchLabelUrl = async (responseSendu, numberOrder) => {
    const maxAttempts = 6;
    let attempts = 0;

    while (attempts < maxAttempts) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/order-api/${responseSendu}/${numberOrder}?shop=${shop}&host=${host}`,
          { method: 'GET' }
        );
        const labelUrl = await response.text();

        if (labelUrl && !labelUrl.startsWith("Error")) {
          return labelUrl;
        }

        attempts++;
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Esperar 1 segundo antes de reintentar
      } catch (error) {
        console.error("Error al obtener la etiqueta:", error);
      }
    }

    throw new Error("No se pudo obtener la etiqueta después de múltiples intentos.");
  };

  const handlePrintLabel = async (responseSendu, numberOrder) => {
    setIsLoading(true);

    try {
      let labelUrl = await fetchLabelUrl(responseSendu, numberOrder);
      setIsLoading(false);

      if (labelUrl) {
        labelUrl = labelUrl.replace(/\\/g, ""); // Remover caracteres de escape (\)
        labelUrl = labelUrl.replace(/["]+/g, ""); // Remover comillas adicionales

        const urlParts = labelUrl.split("://");
        if (urlParts.length === 2) {
          const protocol = urlParts[0];
          const path = urlParts[1].replace(/\/{2,}/g, "/"); // Reemplazar barras dobles en la ruta
          labelUrl = `${protocol}://${path}`;
        }

        console.log("URL limpia:", labelUrl);
        window.open(labelUrl, '_blank', 'noopener,noreferrer');
      } else {
        console.error("No se pudo obtener la URL del label después de múltiples intentos.");
        alert("No se pudo obtener la URL del label. Por favor, inténtalo de nuevo más tarde.");
      }
    } catch (error) {
      console.error("Error al obtener la etiqueta:", error);
      setIsLoading(false);
      alert("Ocurrió un error al obtener la etiqueta. Por favor, inténtalo de nuevo más tarde.");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    consultOrder();
  }, []);

  return (
    <Page title="Órdenes de Envío">
      <Card>
        <IndexTable
          resourceName={resourceName}
          itemCount={listProjects.length}
          selectedItemsCount={
            allResourcesSelected ? 'Todos' : selectedResources.length
          }
          loading={isloading}
          headings={[
            { title: 'Pedido' },
            { title: 'Fecha' },
            { title: 'Destinario' },
            { title: 'Total' },
            { title: 'Estado' },
            { title: 'Código' },
            { hidden: true },
          ]}
          selectable={false}
        >
          {listProjects.map(
            (
              {
                id,
                number_order,
                fecha,
                cliente,
                total,
                precio_entrega,
                estado,
                response_sendu,
              },
              index
            ) => (
              <IndexTable.Row
                id={id}
                key={id}
                selected={selectedResources.includes(id)}
                position={index}
              >
                <IndexTable.Cell>
                  <TextStyle variation="strong">{number_order}</TextStyle>
                </IndexTable.Cell>
                <IndexTable.Cell>{fecha}</IndexTable.Cell>
                <IndexTable.Cell>
                  {cliente.length > 10 ? `${cliente.slice(0, 10)}..` : cliente}
                </IndexTable.Cell>
                <IndexTable.Cell>
                  {toCLP(getTotal(total, precio_entrega))}
                </IndexTable.Cell>
                <IndexTable.Cell>
                  {estado === '1' ? (
                    <Badge
                      status="success"
                      progress="complete"
                      statusAndProgressLabelOverride="Orden de envío generada con éxito"
                    >
                      Emitido
                    </Badge>
                  ) : (
                    <Badge
                      status="info"
                      progress="complete"
                      statusAndProgressLabelOverride="A la espera de orden de envío"
                    >
                      No Emitido
                    </Badge>
                  )}
                </IndexTable.Cell>
                <IndexTable.Cell>{response_sendu}</IndexTable.Cell>
                <IndexTable.Cell>
                  {estado === '1' ? (
                    <>
                      <Button onClick={() => detailOrder(id)}>Ver Orden</Button>
                      <Button primary onClick={() => handlePrintLabel(response_sendu, number_order)}>
                        Imprimir Etiqueta
                      </Button>
                    </>
                  ) : (
                    <Button primary onClick={() => progressOrder(id)}>
                      Generar Orden
                    </Button>
                  )}
                </IndexTable.Cell>
              </IndexTable.Row>
            )
          )}
        </IndexTable>
      </Card>
    </Page>
  );
};

export default Order;
